import injectedModule from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect';
import { init } from '@web3-onboard/react';
import { PROVIDER_API_KEY } from '../constants';

const injected = injectedModule();
const walletConnect = walletConnectModule();

export const initWeb3Onboard = init({
  wallets: [injected, walletConnect],
  chains: [
    {
      id: '0x1',
      token: 'ETH',
      label: 'Ethereum Mainnet',
      rpcUrl: `https://eth-mainnet.g.alchemy.com/v2/${PROVIDER_API_KEY}`
    },
    {
      id: '0x5',
      token: 'gETH',
      label: 'Goerli',
      rpcUrl: `https://eth-goerli.g.alchemy.com/v2/${PROVIDER_API_KEY}`
    },
    {
      id: 1337 as any,
      token: 'ETH',
      label: 'Hardhat',
      rpcUrl: 'http://127.0.0.1:8545/'
    }
  ],
  accountCenter: {
    desktop: {
      enabled: false
    },
    mobile: {
      enabled: false
    }
  },
  appMetadata: {
    name: 'Ghost NFT Collection',
    icon: 'https://proviv.io/wp-content/uploads/2022/04/Asset-1.png',
    logo: 'https://proviv.io/wp-content/uploads/2022/04/Asset-1.png',
    description: 'Ghost NFT Collection'
  }
});

export const formatString = (string: string, startSymbols: number, endSymbols: number): string => {
  return `${string.substr(0, startSymbols)}...${string.substr(string.length - endSymbols, string.length)}`;
};
