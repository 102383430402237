import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Mint from './components/Mint/Mint';

import './App.scss';
import { ConnectionProvider } from './context/Connection';

function App() {
  return (
    <div className='App'>
      <ConnectionProvider>
        <Header />
        <Mint />
        <Footer />
      </ConnectionProvider>
    </div>
  );
}

export default App;
