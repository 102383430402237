import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import { ConnectionContext } from '../../context/Connection';

function Connection() {
  const { connect, connecting } = useContext(ConnectionContext);

  function onConnect() {
    connect().catch((err) => console.log(err));
  }

  if (connecting) {
    return <Spinner animation='grow' className='m-auto d-block' />;
  } else {
    return (
      <Button onClick={onConnect} variant='primary' className='mint-btn w-100 mt-3 px-5'>
        Connect Wallet
      </Button>
    );
  }
}

export default Connection;
