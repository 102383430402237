import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
interface IModalProps {
  show: boolean;
  title: string;
  body: string;
  buttonText?: string;
  buttonAction?: () => void;
}

function MintModal({ show, title, body, buttonText, buttonAction }: IModalProps) {
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>
          <Spinner animation='border' />
          {' ' + title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{body}</p>
      </Modal.Body>

      <Modal.Footer>
        {buttonText && (
          <Button onClick={buttonAction} variant='primary'>
            {buttonText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default MintModal;
