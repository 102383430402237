import { BigNumber } from 'ethers';

import { get } from './httpService';

import { SIGNATURE_API_URL } from './../constants';

export async function getTicketsPerAccount(account: string) {
  return get(`${SIGNATURE_API_URL}/tickets/${account}`) as Promise<{ tickets: number[]; saleStage: string }>;
}

export function unusedTicketsPerAccount(ticketsStorage: BigNumber[], accountTickets: number[]) {
  const ticketsState = ticketsStateFromStorage(ticketsStorage);
  const unusedTicketsPerAccount = [];
  for (const ticketNumber of accountTickets) {
    if (ticketsState[ticketNumber] === '1') {
      unusedTicketsPerAccount.push(ticketNumber);
    }
  }

  return unusedTicketsPerAccount;
}

export async function getSignaturePerAccountsAndTickets(
  account: string,
  unusedTickets: number[],
  desiredAmount: number
) {
  const ticketsToSign = unusedTickets.slice(0, desiredAmount);
  return get(`${SIGNATURE_API_URL}/signature/${account}?tickets=${ticketsToSign.join(',')}`);
}

function ticketsStateFromStorage(ticketsStorage: BigNumber[]) {
  let ticketsState = '';
  for (const slot of ticketsStorage) {
    ticketsState = ticketsState.concat(bigNumToBinary256Array(slot).reverse().join(''));
  }

  return ticketsState;
}

function bigNumToBinary256Array(bigNum: BigNumber) {
  const binary = bigNum.toBigInt().toString(2);
  let zerosToAdd = '';
  for (let i = 0; i < 256 - binary.length; i++) {
    zerosToAdd += '0';
  }

  const binary256 = zerosToAdd + binary;
  return Array.from(binary256);
}
