import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { BigNumber, ethers } from 'ethers';

import * as blockchainService from './../../services/blockchainService';
import * as mintService from './../../services/mintService';

import { ConnectionContext } from '../../context/Connection';
import Connection from './../Connection/Connection';

import { Dream } from '../../types';
import { CHAIN_ID, CONTRACT_ADDRESS } from '../../constants';
import { CONTRACT_ABI } from '../../constants/abis';
import './Mint.scss';
import MintModal from './MintModal/MintModal';

interface ISaleState {
  checker: string;
  price: BigNumber;
  unusedTickets: number[];
  saleStage: string;
}

function Mint() {
  const { wallet, provider, setChain } = useContext(ConnectionContext);
  const [contract, setContract] = useState<Dream | null>(null);
  const [saleState, setSaleState] = useState<ISaleState | null>(null);
  const [inputAmount, setInputAmount] = useState<number>(1);
  const [totalPrice, setTotalPrice] = useState<BigNumber | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [changeNetwork, setChangeNetwork] = useState(false);
  const [insufficientBalance, setInsufficientBalance] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!contract) {
      return;
    }

    handleSaleState();
  }, [contract]);

  useEffect(() => {
    if (!provider || !wallet?.accounts[0]?.address) {
      setContract(null);
      setChangeNetwork(false);
      return;
    }

    let update = true;
    provider.getNetwork().then((network) => {
      if (!update) {
        return;
      }

      if (network.chainId.toString() !== CHAIN_ID) {
        setChangeNetwork(true);
        return;
      } else {
        setChangeNetwork(false);
      }

      setContract(
        blockchainService.getContract(CONTRACT_ADDRESS, CONTRACT_ABI, provider, wallet?.accounts[0].address!) as Dream
      );
    });

    return () => {
      update = false;
    };
  }, [provider, wallet?.accounts]);

  useEffect(() => {
    if (!saleState) {
      setTotalPrice(null);

      return;
    }

    setTotalPrice(saleState?.price.mul(inputAmount));
  }, [inputAmount, saleState]);

  useEffect(() => {
    estimateGas().then((gas) => {
      const bill = (totalPrice || ethers.BigNumber.from(0)).add(gas);
      const balance = ethers.utils.parseEther(
        wallet?.accounts[0]?.balance ? Object.values(wallet?.accounts[0].balance!)[0] : '0'
      );
      if (bill.gt(balance)) {
        setInsufficientBalance(true);
      } else {
        setInsufficientBalance(false);
      }
    });
  }, [totalPrice, saleState]);

  async function handleSaleState() {
    if (!contract) {
      return;
    }

    Promise.all([
      contract.checker(),
      contract.price(),
      contract.getTicketsStorage(),
      mintService.getTicketsPerAccount(wallet?.accounts[0].address!)
    ]).then((results) => {
      for (const value of results) {
        if (!value) {
          return;
        }
      }

      const [checker, price, ticketsStorage, ticketsPerAccount] = results;
      const unusedTickets = mintService.unusedTicketsPerAccount(ticketsStorage, ticketsPerAccount.tickets);
      setSaleState({
        checker,
        price,
        unusedTickets,
        saleStage: ticketsPerAccount.saleStage
      });
    });
  }

  function mint() {
    const account = wallet?.accounts[0]?.address;
    if (!saleState || !account || !inputAmount || !contract || !totalPrice) {
      return;
    }

    setLoading(true);
    mintService
      .getSignaturePerAccountsAndTickets(account, saleState.unusedTickets, inputAmount)
      .then(async (mintData) => {
        return contract
          .mint(mintData.signature, mintData.tickets, { value: totalPrice.toString() })
          .then(async (tx) => {
            setShowModal(true);

            return tx.wait().then((_txMined) => {
              setShowSuccess(true);
              setTimeout(() => {
                setShowSuccess(false);
              }, 8000);
              setInputAmount(1);
            });
          });
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => {
        setShowModal(false);
        handleSaleState().then(() => {
          setLoading(false);
        });
      });
  }

  async function estimateGas(): Promise<BigNumber> {
    const account = wallet?.accounts[0]?.address;
    if (!saleState || !account || !inputAmount || !contract || !totalPrice) {
      return ethers.BigNumber.from(0);
    }

    return mintService
      .getSignaturePerAccountsAndTickets(account, saleState.unusedTickets, inputAmount)
      .then(async (mintData) => {
        return contract.estimateGas.mint(mintData.signature, mintData.tickets, { value: totalPrice.toString() });
      })
      .catch((_err) => {
        return ethers.BigNumber.from(0);
      });
  }

  function showError(err: any) {
    setError(
      err.message
        ? err.message.slice(0, 256) + '...'
        : err.reason
        ? err.reason.slice(0, 256) + '...'
        : 'Something went wrong! Please reload!'
    );
    setTimeout(() => {
      setError(null);
    }, 6000);
  }

  function decreaseAmount() {
    if (inputAmount > 1) {
      setInputAmount(inputAmount - 1);
    }
  }

  function increaseAmount() {
    if (inputAmount < (saleState?.unusedTickets.length || 1)) {
      setInputAmount(inputAmount + 1);
    }
  }

  function setChainHandler() {
    // In case of localhost setup
    const rightChainId = CHAIN_ID === '1337' ? 1337 : '0x' + Number(CHAIN_ID).toString(16);
    setChain({ chainId: rightChainId });
  }

  return (
    <div className='d-flex align-items-center justify-content-center'>
      <Container className='mint-wrapper p-3 rounded m-1 m-sm-5' fluid>
        <Row className='flex-column flex-sm-row'>
          <Col>
            <video loop muted autoPlay={true} className='d-block rounded' width='100%' preload='metadata'>
              <source src='https://proviv.io/vivEyes.mp4' type='video/mp4'></source>
            </video>
          </Col>
          <Col className='mint-form-wrapper d-flex flex-column align-items-center justify-content-center my-4 rounded'>
            <h2 className='text-center'>proVIV NFT Collection</h2>
            <p className='text-center'>Total Supply: 10 001 NFTs</p>
            <p className='text-center'>Mint Stage {saleState?.saleStage || '-'}</p>

            <Col className='mint-form-wrapper d-flex w-75 flex-column align-items-center justify-content-center m-4 rounded'>
              {!showSuccess ? (
                saleState &&
                saleState.unusedTickets &&
                saleState.unusedTickets.length < 1 && (
                  <>
                    <p className='error text-center'>
                      You are not part of Stage {saleState.saleStage} allowlist or already minted your NFTs!
                    </p>
                  </>
                )
              ) : (
                <p className='text-success text-center'>Congratulations! You are part of the proVIV holders!</p>
              )}

              <div className='input d-flex rounded w-100 px-3 my-1 justify-content-between align-items-center'>
                <div className='input-handler d-flex'>
                  <p className='pointer' onClick={decreaseAmount}>
                    -
                  </p>
                  <p>{inputAmount}</p>
                  <p className='pointer' onClick={increaseAmount}>
                    +
                  </p>
                </div>
                <p>{saleState?.unusedTickets.length || '0'} Max</p>
              </div>
              <div className='input d-flex rounded w-100 px-3 my-1 justify-content-between align-items-center'>
                <p className='font-weight-bold'>Price Per NFT</p>
                <p className='font-weight-bold'>
                  {saleState?.price ? ethers.utils.formatEther(saleState?.price).toString() : '0'} ETH
                </p>
              </div>

              <div className='input d-flex rounded w-100 px-3 my-1 justify-content-between align-items-center'>
                <p className='font-weight-bold'>Total Price</p>
                <p className='font-weight-bold'>
                  {totalPrice ? ethers.utils.formatEther(totalPrice).toString() + ' ETH + gas' : '0 ETH'}
                </p>
              </div>
              {changeNetwork ? (
                <Button onClick={setChainHandler} variant='primary' className='mint-btn w-100 mt-3 px-5'>
                  Switch Network
                </Button>
              ) : loading ? (
                <>
                  <Button variant='primary' className='mint-btn w-100 mt-3 px-5' disabled>
                    Loading...
                  </Button>
                  <MintModal
                    show={showModal}
                    title='Processing transaction...'
                    body={`Please, do not close this window!`}
                  />
                </>
              ) : insufficientBalance ? (
                <Button variant='primary' className='mint-btn w-100 mt-3 px-5' disabled>
                  Insufficient Balance
                </Button>
              ) : wallet && contract ? (
                <Button
                  onClick={mint}
                  variant='primary'
                  className='mint-btn w-100 mt-3 px-5'
                  disabled={saleState?.unusedTickets && saleState.unusedTickets.length < 1}
                >
                  Mint
                </Button>
              ) : (
                <Connection />
              )}

              {error && (
                <>
                  <p className='error'>Error:</p>
                  <p className='error'>{error}</p>
                </>
              )}
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Mint;
