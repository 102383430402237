import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { ConnectionContext } from '../../context/Connection';

import { formatString } from '../../services/connectionService';

import './Header.scss';

function Header() {
  const { wallet, disconnect } = useContext(ConnectionContext);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function handleResize() {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else if (window.innerWidth > 767) {
      setIsMobile(false);
    }
  }

  function onDisconnect() {
    disconnect(wallet!);
    window.localStorage.removeItem('connectedWallets');
  }

  return (
    <div className='header d-flex justify-content-center'>
      <div className='header-wrapper d-flex align-items-center px-1 px-md-4'>
        <h1 className='m-0'>
          <img
            style={{ width: isMobile ? '82px' : '132px' }}
            src={
              isMobile
                ? 'https://proviv.io/wp-content/uploads/2022/04/Asset-2.png'
                : 'https://proviv.io/wp-content/uploads/2022/04/Asset-1.png'
            }
            alt='proViv'
          />
        </h1>
        {wallet?.accounts[0] && (
          <div className='d-flex align-items-center'>
            <p className='neutral d-inline-block m-2 text-center'>
              Account: {formatString(wallet?.accounts[0].address, 6, 5)}
            </p>

            <Button onClick={onDisconnect} variant='secondary' className='disconnect d-inline-block px-2 py-1'>
              Disconnect
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
