import axios from 'axios';

export const get = async (endpoint: string, headers?: any): Promise<any> => {
  let url = endpoint;
  if (!endpoint.startsWith('http')) {
    url = `${process.env.REACT_APP_SERVER_URL}${endpoint}`;
  }

  return axios
    .get(url, {
      headers,
      validateStatus: () => true
    })
    .then((res) => {
      switch (res.status) {
        case 200:
          return res.data;
        default:
          throw new Error(res.data.error);
      }
    });
};
